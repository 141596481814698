exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-cannot-connect-mongodb-atlas-inside-gatsby-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/thamy-blog/blog/cannot-connect-mongodb-atlas-inside-gatsby/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-cannot-connect-mongodb-atlas-inside-gatsby-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-how-to-add-review-to-recently-viewed-listing-magento-2-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/thamy-blog/blog/how-to-add-review-to-recently-viewed-listing-magento-2/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-how-to-add-review-to-recently-viewed-listing-magento-2-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-src-pages-projects-content-intellibus-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/thamy-blog/src/pages/projects/content/intellibus.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-src-pages-projects-content-intellibus-mdx" */),
  "component---src-pages-curriculum-index-js": () => import("./../../../src/pages/curriculum/index.js" /* webpackChunkName: "component---src-pages-curriculum-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-content-intellibus-mdx": () => import("./../../../src/pages/projects/content/intellibus.mdx" /* webpackChunkName: "component---src-pages-projects-content-intellibus-mdx" */),
  "component---src-pages-projects-index-js": () => import("./../../../src/pages/projects/index.js" /* webpackChunkName: "component---src-pages-projects-index-js" */),
  "component---src-pages-projects-intellibus-index-js": () => import("./../../../src/pages/projects/intellibus/index.js" /* webpackChunkName: "component---src-pages-projects-intellibus-index-js" */),
  "component---src-pages-projects-todo-index-js": () => import("./../../../src/pages/projects/todo/index.js" /* webpackChunkName: "component---src-pages-projects-todo-index-js" */)
}

